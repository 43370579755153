import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import Jumbotron from "../components/Jumbotron";
import DynamicZone from "../components/DynamicZone";
import { graphql } from "gatsby";
import { SEO } from "../components/Seo";

const Index = ({ location, data }) => {
  const { strapiAppAccueilConf } = data;
  return (
    <Layout location={location}>
      {strapiAppAccueilConf.homePanels && (
        <Jumbotron
          panels={strapiAppAccueilConf.homePanels.panel}
          titre={strapiAppAccueilConf.titre.data.childMarkdownRemark.html}
        />
      )}
      {data.strapiAppAccueilConf.blocks && (
        <DynamicZone blocks={data.strapiAppAccueilConf.blocks} />
      )}
    </Layout>
  );
};

Index.propTypes = {};

export const query = graphql`
  {
    strapiAppAccueilConf {
      locale
      updatedAt
      homePanels {
        panel {
          id
          titre
          destination
          illustration {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  backgroundColor: "#fff"
                )
              }
              name
              publicURL
            }
            mime
            name
          }
        }
      }
      titre {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      blocks {
        __typename
        ...STRAPI__COMPONENT_PAGE_DZ_GALLERIE
        ...STRAPI__COMPONENT_APP_ILLUSTRATED_TEXT
        ...STRAPI__COMPONENT_PAGE_DZ_TEXTE_ENRICHI
        ...STRAPI__COMPONENT_APP_FEATURES_CAROUSEL
      }
      seo {
        metaTitle
        metaDescription
        keywords
        metaRobots
        canonicalURL
        metaImage {
          localFile {
            publicURL
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const { seo } = data.strapiAppAccueilConf;
  return <SEO title={seo.metaTitle} description={seo.metaDescription} keywords={seo.keywords} image={seo.metaImage?.localFile?.childImageSharp?.resize?.src} />;
};

export default Index;
