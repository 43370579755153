import React from "react";
import {JumbotronPanel} from "./jumbotronPanel";

export const Jumbotron = ({ panels, titre }) => {

  return (
    <>
      <div className="flex flex-col lg:flex-row w-full aspect-[21/9] items-stretch font-serif uppercase">
        {
          panels.map(panel => {
              console.log('panel', panel)
            return (
              <JumbotronPanel
                image={panel.illustration}
                destination={panel.destination}
                text={panel.titre}
                key={panel.id}
              />
            );
          })
        }
      </div>
      <div className="container mx-auto my-36 px-4 overflow-hidden">
        <h1 className="whitespace-pre-wrap text-center font-serif text-4xl lg:text-6xl" dangerouslySetInnerHTML={{__html: titre}} />
      </div>
    </>
  );
};

Jumbotron.propTypes = {};

export default Jumbotron;
