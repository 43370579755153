import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import React from "react";

export const JumbotronPanel = ({image, text, destination}) => {

    const imageData = getImage(image.localFile)

    console.log(imageData)

    return (
        <div className="jumbotron-panel h-40 lg:h-auto relative flex lg:flex-1 overflow-hidden bg-gray-600 transition-all lg:hover:flex-[4]">
            <Link
                to={destination}
                className="absolute inset-0 flex items-center justify-center transition-all"
            >
                {imageData && (
                    <div className="absolute inset-0 transition-all
                    ">
                        <GatsbyImage
                            className="h-full w-full transition-all"
                            objectFit="cover"
                            alt={image.name}
                            image={imageData}
                            imgClassName={"absolute inset-0"}
                        />
                    </div>
                )}
                <div
                    className="absolute inset-0 flex items-center justify-center bg-brown-500 bg-opacity-80 text-center text-3xl transition-all hover:bg-opacity-40"
                    dangerouslySetInnerHTML={{__html: text}}
                />
            </Link>
        </div>
    );
};